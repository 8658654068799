import React from "react";
import { graphql, Link } from "gatsby";
import Seo from "../components/seo";
import get from "lodash/get";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import Layout from "../components/layout";
import SidebarContactForm from "../components/sidebar-contact-form";
import PageHero from "../components/page-hero";

class PracticeAreaTemplate extends React.Component {
  render() {
    const practiceArea = get(this.props, "data.contentfulPracticeArea");

    return (
      <Layout location={this.props.location}>
        <Seo
          title={`${practiceArea.title}`}
          description={`Business attorneys and injury attorneys ready to help with your ${practiceArea.title.toLowerCase()} case.`}
        />
        <section className="m-3">
          <nav class="breadcrumb" aria-label="breadcrumbs">
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/practice-areas/">Practice Areas</Link>
              </li>
              <li class="is-active">
                <Link to="#" aria-current="page">
                  {practiceArea.title}
                </Link>
              </li>
            </ul>
          </nav>
          <div className="columns">
            <div className="column">
              <PageHero image={practiceArea.backgroundImage}>
                <div className="is-inline-block has-background-primary">
                  <h1
                    className={"title has-text-white-bis pl-3 pr-4 pt-2 pb-2"}
                  >
                    {practiceArea.title}
                  </h1>
                </div>
              </PageHero>
              <div className="content mt-4">
                {renderRichText(practiceArea.body)}
              </div>
            </div>
            <div className="column is-one-third">
              <SidebarContactForm />
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}

export default PracticeAreaTemplate;

export const pageQuery = graphql`
  query PracticeAreaBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulPracticeArea(slug: { eq: $slug }) {
      slug
      title
      body {
        raw
      }
      shortBody {
        raw
      }
      backgroundImage: image {
        title
        gatsbyImageData(
          layout: FULL_WIDTH
          resizingBehavior: SCALE
          placeholder: BLURRED
        )
      }
    }
  }
`;
